import { graphql } from 'gatsby';
import React from 'react';
import { adaptParagraphs } from '@src/adapters/paragraph';
import { CareerQuery } from '@types';
import { PagePresentation } from '@src/ui';
import { usePageList } from '@src/hooks/page-list';
import {} from '@src/ui';

interface CareerProps {
  data: CareerQuery;
}

const Page: React.FC<CareerProps> = ({ data }) => {
  const pages = usePageList();
  const currentPageData = pages.find(p => p.id === 'presentation-et-parcours');
  const paragraphsData = adaptParagraphs(data.paragraphs);
  const tetrasData = paragraphsData.find(e => e.id == 'tetras-libre');
  const siriusData = paragraphsData.find(e => e.id == 'trophee-de-sirius');
  return (
    <PagePresentation
      image={currentPageData?.image}
      tetrasImage={tetrasData?.image}
      siriusImage={siriusData?.image}
    />
  );
};

export const query = graphql`
  query Career {
    paragraphs: allMarkdownRemark(
      filter: {
        fields: { collection: { eq: "paragraph" } }
        frontmatter: { id: { in: ["tetras-libre", "trophee-de-sirius"] } }
      }
    ) {
      edges {
        node {
          frontmatter {
            id
            image {
              childImageSharp {
                fluid(maxWidth: 330, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default Page;

import {
  MarkdownRemark,
  MarkdownRemarkFrontmatter,
  ParagraphData,
  GatsbyImageSharpFluid_WithWebpFragment,
} from '@types';
import { adaptImage } from './image';

type ParagraphFrontmatter =
  | (Pick<MarkdownRemarkFrontmatter, 'id'> & {
      image?:
        | {
            childImageSharp?:
              | {
                  fluid?:
                    | GatsbyImageSharpFluid_WithWebpFragment
                    | null
                    | undefined;
                }
              | null
              | undefined;
          }
        | null
        | undefined;
    })
  | null
  | undefined;

export const adaptParagraph = (
  data:
    | (Pick<MarkdownRemark, 'html'> & {
        frontmatter?: ParagraphFrontmatter;
      })
    | undefined,
): ParagraphData | null => {
  if (data?.frontmatter?.id) {
    return {
      id: data.frontmatter.id,
      image: adaptImage(data.frontmatter.image),
    };
  }
  return null;
};

export const adaptParagraphs = (data: {
  edges: {
    node: Pick<MarkdownRemark, 'html'> & {
      frontmatter?: ParagraphFrontmatter;
    };
  }[];
}): ParagraphData[] => {
  return data.edges
    .map(e => adaptParagraph(e.node))
    .flatMap(e => (e !== null ? [e] : []));
};
